<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="  تعديل هدف ">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
         
          
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="">
                <b-form-group
                  label-for="re"
                  :state="errors.length > 0 ? false : null"
                >
       
                <label>  الفريق</label>
                <v-select
                  v-model="goalType"
                  :options="optionegoalType"
                  label="title"
                  :reduce="(val) => val.id"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="re"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  الموظف </label>
                    <v-select
                      v-model="user_id"
                      :options="optionemploye"
                      :reduce="(val) => val.value"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="">
                <b-form-group
                  label-for="notes"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الهدف</label>
                <b-form-input v-model="goal" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="4">
              <validation-provider #default="{ errors }" rules="required">
              <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                >
                <label>تاريخ </label>
                <flat-pickr v-model="date"    class="form-control"
                    placeholder="حدد تاريخ " />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="">
                <b-form-group
                  label-for="re"
                  :state="errors.length > 0 ? false : null"
                >
       
                <label>  المدة</label>
                <v-select
                  v-model="duration_type"
                  :options="optiondurationType"
                  label="title"
                  :reduce="(val) => val.id"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
           
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="updateGoal">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> تعديل الهدف</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BTab,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BTabs,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  name: "",

  components: {
    flatPickr,
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BTabs,
    BTab,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      optionerewardType: [{
        label:'عينية',
        value:1
      },
    
      {
        label:'مادية',
        value:0
      }
    ],

    title:'',
    amount:'',
    rewardType: "",
    employe:'',
    duration_type: "",
      goal:'',
      user_id:'',
      goalType: "",
    date:'',
    notes:'',
    date:'',
   
    optionemploye:[], 
    optiondurationType:[  {id:0,title:' يومي'},
        {id:1,title:'  شهري'},
        {id:2,title:'  سنوي'},],
      optionegoalType: [
        {id:0,title:'تسجيل عائلات'},
        {id:1,title:' معالجة حالات'},
        {id:2,title:' فريق جوال'},
    ],
    };
  },

  created() {
    this.getemploye()
    this.getGoal()

  },

  methods: {
    getemploye() {
      this.optionemploye = []
      this.$http.get('/api/v1/get-all-users').then(res => {
        console.log(res);
        const employe = res.data
        employe.forEach(el => {
          this.optionemploye.push({ value: el.id, label: `${el.first_name} ${el.last_name} ` })
        })
        console.log(this.optionemploye);
      })
    },

    getGoal() {
      this.options = [];
      this.$http.get(`/api/v1/achievement_targets/${this.$route.params.id}`).then((res) => {
        //console.log(res.data.data);
        this.goal = res.data.data.goal;
   
        this.duration_type=res.data.data.duration_type;
        this.goalType=res.data.data.goal_type;
        this.date=res.data.data.date;
        this.user_id = res.data.data.user_id
      });
    },
 
    updateGoal() {
      let data = {
        goal: this.goal,
  
        duration_type:this.duration_type,
        date:this.date,
        goal_type:this.goalType,
        user_id: this.user_id
      };
      let url=`/api/v1/achievement_targets/${this.$route.params.id}`
       this.$http.put(url,data).then((res) => {
      
        //console.log(res);
        this.$swal({
          title: "تم التعديل بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
         
      });
    }
    //
  }
};
</script> 

<style scoped>
.form-control {
  font-weight: bold;
}
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}


.align-left {
  margin-left: 750px;
}
.but {
  top: 40px;
}
</style>